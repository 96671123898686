import Box from '@mui/material/Box'

import { Text } from '../index'

export type Segment = {
    value: number
    unit: string
    colour: string
    label: string
    order: number
    percentage?: number
}

const SegmentedBar = ({ segments }: { segments: Segment[] }) => {
    return (
        <Box sx={{ display: 'flex', height: 12, padding: '16px 0 16px 0' }}>
            {segments.map((segment, index) => (
                <Box
                    key={index}
                    sx={{
                        width: `${segment.percentage}%`,
                        bgcolor: segment.colour,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
            ))}
        </Box>
    )
}

const HorizontalSegmentedBar = ({
    segments,
    legendOptions,
}: {
    segments: Segment[]
    legendOptions: {
        ontop: boolean
        vertical: boolean
    }
}) => {
    const total = segments.reduce((sum, item) => sum + item.value, 0)
    segments = segments
        .sort((a: Segment, b: Segment) => a.order - b.order)
        .map((segment) => {
            segment.percentage = segment.value > 0 ? (segment.value / total) * 100 : 0
            return segment
        })

    return (
        <Box sx={{ width: '100%' }}>
            {!legendOptions.ontop && <SegmentedBar segments={segments} />}
            <Box sx={{ mt: 5, mb: 1 }}>
                {segments.map((segment, index) => (
                    <Box
                        key={`legend-item-${index}`}
                        sx={{
                            display: legendOptions.vertical ? 'flex' : 'inline-flex',
                            alignItems: 'left',
                            justifyContent: 'flex-start',
                            gap: legendOptions.vertical ? 3 : '4px',
                            p: 1,
                            height: legendOptions.vertical ? '48px' : '24px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box
                            sx={{
                                flex: '1 0 auto',
                                width: legendOptions.vertical ? '20px' : '13.33px',
                                height: legendOptions.vertical ? '20px' : '13.33px',
                                borderRadius: '100%',
                                backgroundColor: segment.colour,
                            }}
                        />
                        <Text
                            variant={legendOptions.vertical ? 'body2' : 'caption'}
                            sx={{
                                flex: '1 1 auto',
                                width: '100%',
                            }}
                        >
                            {`${segment.label}: ${segment.value.toFixed(2)} ${segment.unit} (${segment.percentage! < 1 && segment.percentage! > 0 ? '< 1' : Math.round(segment.percentage!)}%)`}
                        </Text>
                    </Box>
                ))}
            </Box>
            {legendOptions.ontop && <SegmentedBar segments={segments} />}
        </Box>
    )
}

export default HorizontalSegmentedBar
