import { Button, Text, ToggleTabs, useIsMobile } from '@lune-fe/lune-ui-lib'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'
import { Box, Stack } from '@mui/material'
import fileDownload from 'js-file-download'
import DownloadDropdown from 'lune-ui-lib/src/components/DownloadDropdown'
import HorizontalSegmentedBar, { Segment } from 'lune-ui-lib/src/components/HorizontalSegmentedBar'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Range } from 'react-date-range'

import { formatNumbers } from '../../utils/utils'
import {
    downloadImageFromHtml,
    emissionsChartColors,
    emissionsChartLabels,
    IEmissionsTimeseries,
    IWtwBreakdownTimeseries,
    logisticsChartOrdering,
    LogisticsEmissionsCategories,
    wtwBreakdownChartColors,
    wtwBreakdownChartLabels,
    wtwBreakdownChartOrdering,
    WtwStage,
} from '../charts/chartUtils'
import EmissionsChart from '../charts/EmissionsChart'

const baseCategoryBarData = {
    air: {
        value: 0,
        label: 'Air',
        unit: 't',
        order: logisticsChartOrdering.air,
        colour: '#3BC9F0',
    },
    sea: {
        value: 0,
        label: 'Sea',
        unit: 't',
        order: logisticsChartOrdering.sea,
        colour: '#0099F1',
    },
    road: {
        value: 0,
        label: 'Road',
        order: logisticsChartOrdering.road,
        unit: 't',
        colour: '#69DFC9',
    },
    rail: {
        value: 0,
        label: 'Rail',
        order: logisticsChartOrdering.rail,
        unit: 't',
        colour: '#C4EB9E',
    },
    'inland waterway': {
        value: 0,
        label: 'Inland waterway',
        unit: 't',
        order: logisticsChartOrdering.inlandWaterway,
        colour: '#FDF07D',
    },
    'logistics sites': {
        value: 0,
        label: 'Logistics sites',
        unit: 't',
        order: logisticsChartOrdering.logisticSite,
        colour: '#FFE175',
    },
}

const baseWtwBarData = {
    'Tank-To-Wheel': {
        value: 0,
        label: 'Tank-To-Wheel',
        unit: 't',
        order: wtwBreakdownChartOrdering.ttw,
        colour: '#3BC9F0',
    },
    'Well-To-Tank': {
        value: 0,
        label: 'Well-To-Tank',
        unit: 't',
        order: wtwBreakdownChartOrdering.wtt,
        colour: '#0099F1',
    },
    'unknown stage': {
        value: 0,
        label: 'Unknown stage',
        unit: 't',
        order: wtwBreakdownChartOrdering.unknown,
        colour: '#BDBDBD',
    },
}

export const EmissionsSectionChartToggle: FC<{
    data: {
        emissionTimeseries: IEmissionsTimeseries[]
        wtwBreakdownTimeseries: IWtwBreakdownTimeseries[]
    }
    dateRange: Range
    loading: boolean
    emissionsListProps?: {
        organisationId: string
        type: string
        accountHandle: string
    }
    csvDownloadFn?: (
        organisationId: string,
        type: string,
        accountHandle: string,
        from: string,
        through: string,
    ) => Promise<string>
}> = ({
    data: { emissionTimeseries, wtwBreakdownTimeseries },
    dateRange,
    loading,
    emissionsListProps,
    csvDownloadFn,
}) => {
    const printRef = useRef<HTMLElement>(null)
    const { isMobile } = useIsMobile()
    const [total, setTotal] = useState<string>()
    const [categoryBarData, setCategoryBarData] = useState<Segment[]>([
        baseCategoryBarData.air,
        baseCategoryBarData.sea,
        baseCategoryBarData.road,
        baseCategoryBarData.rail,
        baseCategoryBarData['inland waterway'],
        baseCategoryBarData['logistics sites'],
    ])
    const [wtwBarData, setWtwBarData] = useState<Segment[]>([
        baseWtwBarData['unknown stage'],
        baseWtwBarData['Tank-To-Wheel'],
        baseWtwBarData['Well-To-Tank'],
    ])

    const downloadCsv = () => {
        if (csvDownloadFn && emissionsListProps) {
            csvDownloadFn(
                emissionsListProps.organisationId,
                emissionsListProps.type,
                emissionsListProps.accountHandle,
                dateRange.startDate!.toLocaleDateString('sv-SE'),
                dateRange.endDate!.toLocaleDateString('sv-SE'),
            ).then((data) =>
                fileDownload(data, `logistics-emissions-${emissionsListProps.accountHandle}.csv`),
            )
        }
    }

    const downloadChart = () => {
        if (printRef.current) {
            downloadImageFromHtml(printRef.current, `emissions-graph`)
        }
    }

    const emissionsListPath: string | undefined = useMemo(() => {
        if (!emissionsListProps) {
            return undefined
        }
        const { organisationId, type, accountHandle } = emissionsListProps

        return type === 'live'
            ? `/logistics/${organisationId}/${accountHandle}/emissions`
            : `/logistics/test/${organisationId}/${accountHandle}/emissions`
    }, [
        emissionsListProps?.organisationId,
        emissionsListProps?.type,
        emissionsListProps?.accountHandle,
    ])

    useEffect(() => {
        let airTotal = 0
        let seaTotal = 0
        let roadTotal = 0
        let railTotal = 0
        let inlandWaterwayTotal = 0
        let logisticsSiteTotal = 0
        let wttTotal = 0
        let ttwTotal = 0
        let wtwUnknownTotal = 0

        for (const item of emissionTimeseries) {
            airTotal += item.air
            seaTotal += item.sea
            roadTotal += item.road
            railTotal += item.rail
            inlandWaterwayTotal += item.inlandWaterway
            logisticsSiteTotal += item.logisticSite
        }
        for (const item of wtwBreakdownTimeseries) {
            wttTotal += item.wtt
            ttwTotal += item.ttw
            wtwUnknownTotal += item.unknown
        }

        const barCategoryData: Segment[] = [
            {
                ...baseCategoryBarData.air,
                value: airTotal,
            },
            {
                ...baseCategoryBarData.sea,
                value: seaTotal,
            },
            {
                ...baseCategoryBarData.road,
                value: roadTotal,
            },
            {
                ...baseCategoryBarData.rail,
                value: railTotal,
            },
            {
                ...baseCategoryBarData['inland waterway'],
                value: inlandWaterwayTotal,
            },
            {
                ...baseCategoryBarData['logistics sites'],
                value: logisticsSiteTotal,
            },
        ].filter((s) => s.value > 0)
        const barWtwData: Segment[] = [
            {
                ...baseWtwBarData['Well-To-Tank'],
                value: wttTotal,
            },
            {
                ...baseWtwBarData['unknown stage'],
                value: wtwUnknownTotal,
            },
            {
                ...baseWtwBarData['Tank-To-Wheel'],
                value: ttwTotal,
            },
        ].filter((s) => s.label !== baseWtwBarData['unknown stage'].label || s.value > 0)
        setTotal(
            formatNumbers(
                airTotal +
                    seaTotal +
                    roadTotal +
                    railTotal +
                    inlandWaterwayTotal +
                    logisticsSiteTotal,
                2,
            ).toString(),
        )
        setCategoryBarData(barCategoryData)
        setWtwBarData(barWtwData)
    }, [emissionTimeseries, wtwBreakdownTimeseries])

    const downloadButton = csvDownloadFn ? (
        <DownloadDropdown
            downloadPropMap={{
                csv: {
                    downloadFn: downloadCsv,
                    title: 'CSV',
                    icon: <TableChartOutlinedIcon />,
                },
                chart: {
                    downloadFn: downloadChart,
                    title: 'Image',
                    icon: <BarChartOutlinedIcon />,
                },
            }}
        />
    ) : (
        <Button
            onClick={() => {
                if (printRef.current) {
                    downloadImageFromHtml(printRef.current, `shipping-emissions-graph`)
                }
            }}
            leftIcon={<FileDownloadOutlinedIcon />}
            variant={'outlined'}
        >
            Download graph
        </Button>
    )

    const byCategoryChart = (
        <>
            <HorizontalSegmentedBar
                segments={categoryBarData}
                legendOptions={{ ontop: true, vertical: false }}
            />
            <EmissionsChart
                loading={loading}
                data={emissionTimeseries}
                dataColours={emissionsChartColors}
                dataLabels={emissionsChartLabels}
                dateRange={dateRange}
                showLegend={false}
                skipZeroOnTooltip={false}
                labelOrdering={(a: LogisticsEmissionsCategories, b: LogisticsEmissionsCategories) =>
                    logisticsChartOrdering[a] - logisticsChartOrdering[b]
                }
            />
        </>
    )
    const byWtwChart = (
        <>
            <HorizontalSegmentedBar
                segments={wtwBarData}
                legendOptions={{ ontop: true, vertical: false }}
            />
            <EmissionsChart
                loading={loading}
                data={wtwBreakdownTimeseries}
                dataColours={wtwBreakdownChartColors}
                dataLabels={wtwBreakdownChartLabels}
                dateRange={dateRange}
                showLegend={false}
                skipZeroOnTooltip={false}
                labelOrdering={(a: WtwStage, b: WtwStage) =>
                    wtwBreakdownChartOrdering[a] - wtwBreakdownChartOrdering[b]
                }
            />
        </>
    )

    return (
        <>
            <Box sx={{ mt: isMobile ? 5 : 9 }}>
                <Box ref={printRef} sx={{ mt: '0 !important' }}>
                    <Text variant={'h4'} sx={{ mb: isMobile ? 5 : 9 }}>
                        Our shipments have emitted {total} tCO₂e
                    </Text>
                    {
                        // For estimates before this date there will not be WTW breakdown data since
                        // emission factors were not introduced. Do not show WTW breakdown graph
                        // Note: We are aware that shipped_at !== created_at, meaning that we might
                        // be hiding some valid WTW breakdown data with this filter, even so we have
                        // chosen to press onward with this change since showing empty data looks bad.
                        dateRange.startDate && dateRange.startDate < new Date('2024-11-08') ? (
                            byCategoryChart
                        ) : (
                            <ToggleTabs defaultActive={location.hash.slice(1)}>
                                <ToggleTabs.Tab label={'By category'} value={'category'}>
                                    {byCategoryChart}
                                </ToggleTabs.Tab>
                                <ToggleTabs.Tab label={'By WTW stage'} value={'wtwStage'}>
                                    {byWtwChart}
                                </ToggleTabs.Tab>
                            </ToggleTabs>
                        )
                    }
                </Box>
            </Box>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                {emissionsListPath && (
                    <Button
                        leftIcon={<ListAltOutlinedIcon />}
                        variant={'outlined'}
                        href={emissionsListPath}
                    >
                        View individual calculations
                    </Button>
                )}
                {!isMobile && dateRange.startDate && dateRange.endDate && downloadButton}
            </Stack>
        </>
    )
}

export default EmissionsSectionChartToggle
