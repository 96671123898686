import {
    CalculatedRoute,
    ConvertedShipment,
    Distance,
    DistanceCalculationDetails,
    DistanceCalculationMethod,
    EmissionFactorWithGasEmissions,
    EstimateMassUnit,
    LogisticsSiteMethod,
    MassUnit,
    MethodologyDetails as LuneMethodologyDetails,
    NullEnum,
    RoadMethodologyDetails,
    RoadShippingMethod,
    Shipment,
    ShipmentConversionMethod,
    ShippingMethod,
    ShippingRoute,
    VesselInferenceMethod,
    VesselInferenceMethodDetails,
} from '@lune-climate/lune'
import { Text, TooltipList } from '@lune-fe/lune-ui-lib'
import Big from 'big.js'
import { JSX, useMemo } from 'react'
import { None, Some } from 'ts-results-es'

import { formatNumbers, optionFromNullable, toTitleCase } from './../../utils/utils'
import { formattedValue, getDistanceExplanationText } from './utils/explanationUtils'
import {
    getCapacityUtilisationLabel,
    getEmptyRunsFactorLabel,
    getLoadFactorLabel,
    getRoadEmissionStandard,
    getRoadGrandientAndSituationLabel,
    getRoadVehicleName,
    parseVesselInferenceDetails,
} from './utils/logisticsUtils'
import ExplanationChip from './ExplanationChip'
import { ExplanationStep, ExplanationText } from './ExplanationSteps'

type EmissionsValue = {
    amount: string
    unit: EstimateMassUnit
}

export interface EmissionsExplanationSectionProps {
    emissions: EmissionsValue & {
        wtwDetails?: { wtt: EmissionsValue; ttw: EmissionsValue; unknown: EmissionsValue } | null
    }
    /**
     * If `responseRoute` is non-null then this property has to be non-null as well.
     */
    distance: Distance | undefined
    adjustedDistance: Distance | undefined
    load: Shipment
    distanceCalculationMethod: DistanceCalculationMethod | NullEnum
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum
    convertedLoad: ConvertedShipment | NullEnum
    loadConversionMethod: ShipmentConversionMethod | NullEnum
    methodology: string[]
    emissionFactor: EmissionFactorWithGasEmissions
    /**
     * If `responseRoute` is non-null then this property has to be non-null as well.
     */
    requestRoute: ShippingRoute | null
    requestMethod: ShippingMethod | LogisticsSiteMethod
    responseRoute: CalculatedRoute | null
    methodologyDetails:
        | MethodologyDetails
        | RoadMethodologyDetails
        | ElectricMethodMethodologyDetails
        | null
    vesselInference: VesselInferenceMethod | null
    mapboxAccessToken?: string
    hideEmissionFactorUrl?: boolean
    emissionsUnit?: MassUnit
}

export type MethodologyDetails = {
    flight?: FlightMethodologyDetails
}

export type ElectricMethodMethodologyDetails = {
    electricityConsumption: {
        amount: string
        unit: 'kWh/tkm'
    }
}

export type FlightMethodologyDetails = {
    aircraftName: string
    aircraftConfiguration: LuneMethodologyDetails.aircraft_configuration
    aircraftCapacityTonnes: {
        passenger: number
        cargo: number
    }
    loadFactor: {
        passenger: number
        cargo: number
    }
    flightFuelConsumedTonnes: number
    distanceAdjustmentFactor: number
    emissionShare: number
}

function pickDistanceCalculationDetails(
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum,
): string | null {
    if (distanceCalculationDetails === null) {
        return null
    }

    if ('vesselTracking' in distanceCalculationDetails) {
        if (distanceCalculationDetails.vesselTracking === null) {
            return null
        }
        if ('message' in distanceCalculationDetails.vesselTracking) {
            return distanceCalculationDetails.vesselTracking.message
        }
        if ('adjustments' in distanceCalculationDetails.vesselTracking) {
            return distanceCalculationDetails.vesselTracking.adjustments.join('. ')
        }
    }

    return null
}

export const getRoadLoadExplanations = (
    method: RoadShippingMethod,
    methodologyDetails: RoadMethodologyDetails,
): ExplanationStep => {
    const chips = [
        <ExplanationChip
            key={`key-payload-capacity`}
            text={`${methodologyDetails.vehicleCapacity} t payload capacity`}
        />,
    ]
    if (!method.loadFactor) {
        chips.push(
            <ExplanationChip
                key={`key-load-factor`}
                text={getLoadFactorLabel(methodologyDetails.loadFactor)}
            />,
        )
    }
    if (!method.emptyRunsFactor) {
        chips.push(
            <ExplanationChip
                key={`key-empty-runs-factor`}
                text={getEmptyRunsFactorLabel(methodologyDetails.emptyRunsFactor)}
            />,
        )
    }

    const elements = chips.reduce(
        (result: React.ReactNode[], node: React.ReactNode, index: number) => {
            if (index === 0) {
                return [node]
            }
            if (index === chips.length - 1) {
                return [...result, <ExplanationText key={`key-and`} text="and" />, node]
            }
            return [...result, <ExplanationText key={`key-comma-${index}`} text="," />, node]
        },
        [],
    )

    return {
        description: (
            <>
                <ExplanationText text="Assumed" />
                {...elements}
            </>
        ),
    }
}

export const getElectricEnergyConsumptionExplanation = (
    methodologyDetails: ElectricMethodMethodologyDetails,
): ExplanationStep => {
    // if there's a slash, add a space before and after, otherwise nothing is replaced
    const unit = methodologyDetails.electricityConsumption.unit.replace(/(\w+)\/(\w+)/, '$1 / $2')
    return {
        description: (
            <>
                <ExplanationText text="Calculated a vehicle electricity consumption of" />
                <ExplanationChip
                    text={`${Big(methodologyDetails.electricityConsumption.amount).round(4)} ${unit}`}
                />
            </>
        ),
        info: (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Calculation based on GLEC methodology.
                </Text>
            </>
        ),
    }
}

export const getRoadEnergyConsuptionExplanation = (
    method: RoadShippingMethod,
    methodologyDetails: RoadMethodologyDetails,
): ExplanationStep => {
    // if there's a slash, add a space before and after, otherwise nothing is replaced
    const unit = methodologyDetails.energyConsumption.unit.replace(/(\w+)\/(\w+)/, '$1 / $2')
    return {
        description: (
            <>
                <ExplanationText text="Calculated a vehicle energy consumption of" />
                <ExplanationChip
                    text={`${Big(methodologyDetails.energyConsumption.amount).round(4)} ${unit}`}
                />
            </>
        ),
        info: (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Calculation based on HBEFA data:
                </Text>
                <TooltipList
                    items={[
                        getRoadVehicleName(method.vehicleType),
                        getCapacityUtilisationLabel(methodologyDetails.capacityUtilisation),
                        getRoadEmissionStandard(methodologyDetails.emissionStandard),
                        getRoadGrandientAndSituationLabel(
                            methodologyDetails.gradient,
                            methodologyDetails.situation,
                        ),
                    ]}
                />
            </>
        ),
    }
}

export const getAircraftTypeExplanation = (
    requestMethod: ShippingMethod | LogisticsSiteMethod,
    flightDetails: FlightMethodologyDetails,
): ExplanationStep => {
    if (!(typeof requestMethod === 'object' && 'flightNumber' in requestMethod)) {
        throw new Error('Flight number is an expected request input')
    }
    return {
        description: (
            <>
                <ExplanationText text="Identified" />
                <ExplanationChip text={flightDetails.aircraftName} />
                <ExplanationText text="from flight number" />
                <ExplanationChip text={requestMethod.flightNumber} />
            </>
        ),
    }
}

const getFuelUsageExplanation = (flightDetails: FlightMethodologyDetails): ExplanationStep => {
    const fuelUsed = flightDetails.flightFuelConsumedTonnes * flightDetails.emissionShare
    return {
        description: (
            <>
                <ExplanationText text="Calculated the shipment's fuel usage share as" />
                <ExplanationChip text={`${fuelUsed.toFixed(2)} t`} />
            </>
        ),
        info: (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Capacity:{' '}
                    {flightDetails.aircraftCapacityTonnes.cargo +
                        flightDetails.aircraftCapacityTonnes.passenger}{' '}
                    tonnes
                </Text>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Configuration: {flightDetails.aircraftConfiguration.replaceAll('_', ' ')}
                </Text>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Load factor: {Big(flightDetails.loadFactor.cargo).mul(100).round(2).toString()}%
                </Text>
            </>
        ),
    }
}

const getAirShipmentDistanceInfo = (
    distance: Distance | undefined,
    adjustedDistance: Distance | undefined,
): string | undefined => {
    if (!distance || !adjustedDistance) {
        return
    }
    const DAF = Number(adjustedDistance.amount) - Number(distance.amount)
    return `Including a Distance Adjustment Factor (DAF) of ${DAF} km`
}

const airShipmentFormulaExplanation: ExplanationStep = {
    description: (
        <ExplanationText text="Multiplied the Emission Factor by the shipment's fuel usage share" />
    ),
}

const getRouteNodesNames = (route: CalculatedRoute): string[] => {
    return route.legs
        .map(({ location: { label } }) => label)
        .filter((label): label is string => label !== null)
}

export const filterStops = (nodeNames: string[]): string[] => {
    const locodeRegex = /\(LOCODE [A-Z]{2}[A-Z0-9]{3}\)/
    return nodeNames.filter((name) => locodeRegex.test(name))
}

export const formatStop = (name: string): string => {
    const regex = /^([^,]+).*\(LOCODE ([A-Z]{2}[A-Z0-9]{3})\)/
    const match = name.match(regex)
    if (match) {
        const location = match[1].toLowerCase()
        const locode = match[2]
        const formattedLocation = location.trim().split(' ').map(toTitleCase).join(' ')
        return `${formattedLocation} ${locode}`
    }
    return name
}

const filterAndTransformStops = (nodeNames: string[]): string[] => {
    return filterStops(nodeNames).map(formatStop)
}

export const formatCarrierScheduleStopsTooltipSubject = (s: string): React.ReactNode => {
    const regex = /(.*?)\s*(Journey.*)/
    const result = s.match(regex)
    if (result) {
        const [, part1, part2] = result
        return (
            <>
                {part1}
                <br />
                {part2}
            </>
        )
    }
    // default to showing the string as-is
    return <>{s}</>
}

const getCarrierSchedulesStopTooltip = ({
    subject,
    stops,
}: {
    subject: string
    stops: string[]
}): JSX.Element => {
    return (
        <>
            {formatCarrierScheduleStopsTooltipSubject(subject)}
            {stops.length > 0 && <TooltipList items={stops} />}
        </>
    )
}

// eslint-disable-next-line complexity
const getTooltipMessage = ({
    distanceCalculationMethod,
    distanceCalculationDetails,
    distance,
    adjustedDistance,
    formattedDistance,
    responseRoute,
    vesselInference,
}: {
    distanceCalculationMethod: DistanceCalculationMethod
    distanceCalculationDetails: DistanceCalculationDetails | null
    distance: Distance | undefined
    adjustedDistance: Distance | undefined
    formattedDistance: number | string
    responseRoute: CalculatedRoute | null
    vesselInference: VesselInferenceMethod | null
}): JSX.Element | string | null => {
    if (
        distanceCalculationMethod === DistanceCalculationMethod.CARRIER_SCHEDULE_STOPS &&
        responseRoute &&
        distanceCalculationDetails?.carrierScheduleStops?.message
    ) {
        return getCarrierSchedulesStopTooltip({
            subject: distanceCalculationDetails.carrierScheduleStops.message,
            stops: filterAndTransformStops(getRouteNodesNames(responseRoute)),
        })
    }

    if (distanceCalculationMethod === 'great_circle_distance') {
        return getAirShipmentDistanceInfo(distance, adjustedDistance) ?? null
    }
    const vesselInferenceTooltip =
        vesselInference && 'error' in vesselInference ? vesselInference.error : null

    const distanceTooltip =
        distance && distance.amount !== adjustedDistance?.amount
            ? `We calculated a Shortest Feasible Distance of ${formattedDistance} km, then adjusted by +15%, as per GLEC guidelines for container ships.`
            : null

    if (vesselInferenceTooltip === null && distanceTooltip === null) {
        return null
    }
    if (vesselInferenceTooltip !== null && distanceTooltip !== null) {
        return (
            <>
                {vesselInferenceTooltip}
                <br />
                {distanceTooltip}
            </>
        )
    }
    return vesselInferenceTooltip ?? distanceTooltip
}

export const getDistanceExplanation = (
    distance: Distance | undefined,
    adjustedDistance: Distance | undefined,
    distanceCalculationMethod: DistanceCalculationMethod | NullEnum,
    distanceCalculationDetails: DistanceCalculationDetails | NullEnum,
    responseRoute: CalculatedRoute | null,
    vesselInference: VesselInferenceMethod | null,
): ExplanationStep => {
    if (
        !distance ||
        !distanceCalculationMethod ||
        distanceCalculationMethod === DistanceCalculationMethod.USER_INPUT
    ) {
        return { description: undefined }
    }

    const formattedDistance = formatNumbers(distance.amount, 2)
    const formattedAdjustedDistance = formatNumbers(adjustedDistance!.amount, 2)

    const step = (
        <>
            <ExplanationText text="Calculated a distance of" />
            <ExplanationChip text={formattedAdjustedDistance + ' km'} />
            <ExplanationText text="using" />
            <ExplanationChip text={getDistanceExplanationText(distanceCalculationMethod)} />
        </>
    )
    const vesselTrackingStep = (
        <>
            <ExplanationText text="Tracked the vessel for" />
            <ExplanationChip text={formattedDistance + ' km'} />
            <ExplanationText text="using" />
            <ExplanationChip text={'AIS data'} />
        </>
    )
    const carrierScheduleStep = (
        <>
            <ExplanationText text="Calculated a distance of" />
            <ExplanationChip text={formattedAdjustedDistance + ' km'} />
            <ExplanationText text="using" />
            <ExplanationChip
                text={getDistanceExplanationText(DistanceCalculationMethod.SEA_DISTANCE_ALGORITHM)}
            />
            <ExplanationText text="and" />
            <ExplanationChip text={`carrier schedule`} />
        </>
    )

    const tooltipMessage = getTooltipMessage({
        distanceCalculationMethod,
        distanceCalculationDetails,
        distance,
        adjustedDistance,
        formattedDistance,
        responseRoute,
        vesselInference,
    })

    return {
        description:
            distanceCalculationMethod === DistanceCalculationMethod.VESSEL_TRACKING
                ? vesselTrackingStep
                : distanceCalculationMethod === DistanceCalculationMethod.CARRIER_SCHEDULE_STOPS
                  ? carrierScheduleStep
                  : step,
        info: tooltipMessage ?? pickDistanceCalculationDetails(distanceCalculationDetails ?? null),
    }
}

const getShipmentExplanation = (
    load: Shipment,
    convertedLoad: ConvertedShipment | NullEnum,
    loadConversionMethod: ShipmentConversionMethod | NullEnum,
): ExplanationStep => {
    const formattedLoad =
        'containers' in load
            ? `${formattedValue(load.containers)} TEU`
            : `${formattedValue(load.mass.amount)} ${load.mass.unit}`
    const formattedConvertedShipment = convertedLoad
        ? `${formattedValue(convertedLoad.amount)} ${
              convertedLoad.unit === 'TEU' ? ' TEU' : convertedLoad.unit
          }`
        : ''

    if (loadConversionMethod === ShipmentConversionMethod.USER_PROVIDED_CARGO_TYPE) {
        return {
            description: (
                <>
                    <ExplanationText text="Converted" />
                    <ExplanationChip text={formattedLoad} testId={'converted-load'} />
                    <ExplanationText text="to" />
                    <ExplanationChip text={formattedConvertedShipment} />
                    <ExplanationText
                        text={`given user-inputted cargo type, to match the Emission Factor's unit`}
                    />
                </>
            ),
        }
    }
    if (loadConversionMethod === ShipmentConversionMethod.AVERAGE_CARGO_TYPE) {
        return {
            description: (
                <>
                    <ExplanationText text="Converted" />
                    <ExplanationChip text={formattedLoad} testId={'converted-load'} />
                    <ExplanationText text="to" />
                    <ExplanationChip text={formattedConvertedShipment} />
                    <ExplanationText
                        text={`assuming average cargo type, to match the Emission Factor's unit`}
                    />
                </>
            ),
        }
    }

    return { description: undefined }
}

const getFormulaExplanation = ({
    distance,
    isDetailedRoadEstimate,
    isElectricityDerivedMethod,
}: {
    distance: Distance | undefined
    isDetailedRoadEstimate: boolean
    isElectricityDerivedMethod: boolean
}): ExplanationStep => {
    if (isElectricityDerivedMethod) {
        return {
            description: (
                <ExplanationText text="Multiplied the Emission Factor by the electricity consumption, distance and load" />
            ),
        }
    }
    if (isDetailedRoadEstimate) {
        return {
            description: (
                <ExplanationText text="Multiplied the Emission Factor by the energy consumption, distance and load" />
            ),
        }
    }
    if (distance) {
        return {
            description: (
                <ExplanationText text="Multiplied the Emission Factor by the distance and load" />
            ),
        }
    }
    return {
        description: <ExplanationText text="Multiplied the Emission Factor by the load" />,
    }
}

const getEmissionFactorExplanation = (
    methodology: string[],
    emissionFactor: EmissionFactorWithGasEmissions,
    options?: {
        basedOnInput?: boolean
    },
): ExplanationStep => {
    let emissionFactorMessage: string | null = null
    if (
        methodology.includes('imo_unavailable_container_ship_fallback') ||
        methodology.includes('imo_unavailable_container_ship_trade_lane_fallback')
    ) {
        emissionFactorMessage = `We couldn't find an Emission Factor for the inputted vessel`
    }
    return {
        description: (
            <>
                <ExplanationText text="Selected the " />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText
                    text={` Emission Factor${options?.basedOnInput ? ' based on your input' : ''}`}
                />
            </>
        ),
        info: emissionFactorMessage,
    }
}

function getInferredVesselExplanation(details: VesselInferenceMethodDetails): ExplanationStep {
    const vessel = parseVesselInferenceDetails(details.details)
        // eslint-disable-next-line react/jsx-key
        .map((vessel) => <ExplanationChip text={vessel.formattedVessel} />)
        // A fallback in case the format of the vessel inference details changes and
        // we (temporarily) can't identify vessels.
        .unwrapOr(<ExplanationText text="the vessel" />)
    return {
        description: (
            <>
                <ExplanationText text="Identified " />
                {vessel}
            </>
        ),
    }
}

export const useExplanationSteps = ({
    distance,
    adjustedDistance,
    distanceCalculationMethod,
    distanceCalculationDetails,
    load,
    convertedLoad,
    loadConversionMethod,
    methodology,
    emissionFactor,
    requestMethod,
    responseRoute,
    methodologyDetails,
    vesselInference,
}: EmissionsExplanationSectionProps) => {
    const distanceExplanation = useMemo(() => {
        return getDistanceExplanation(
            distance,
            adjustedDistance,
            distanceCalculationMethod,
            distanceCalculationDetails,
            responseRoute,
            vesselInference,
        )
    }, [
        distance,
        adjustedDistance,
        distanceCalculationMethod,
        distanceCalculationDetails,
        responseRoute,
        vesselInference,
    ])

    const inferredVesselExplanation = useMemo(
        () =>
            optionFromNullable(vesselInference)
                .andThen((details) => ('details' in details ? Some(details) : None))
                .map(getInferredVesselExplanation),
        [vesselInference],
    )

    const shipmentExplanation = useMemo(() => {
        return getShipmentExplanation(load, convertedLoad, loadConversionMethod)
    }, [load, convertedLoad, loadConversionMethod])

    const isDetailedRoadEstimate =
        typeof requestMethod === 'object' &&
        'vehicleType' in requestMethod &&
        'fuel' in requestMethod

    const isElectricityDerivedMethod = !!(
        methodologyDetails && 'electricityConsumption' in methodologyDetails
    )

    const formulaExplanation = useMemo(() => {
        return getFormulaExplanation({
            distance,
            isDetailedRoadEstimate,
            isElectricityDerivedMethod,
        })
    }, [distance, isDetailedRoadEstimate])

    const emissionFactorExplanation = useMemo(() => {
        return getEmissionFactorExplanation(methodology, emissionFactor, {
            basedOnInput: isDetailedRoadEstimate,
        })
    }, [methodology, emissionFactor, isDetailedRoadEstimate])

    if (methodologyDetails && 'flight' in methodologyDetails && methodologyDetails.flight) {
        const flightDetails = methodologyDetails.flight
        return [
            {
                description: distanceExplanation.description,
                info: getAirShipmentDistanceInfo(distance, adjustedDistance),
            },
            getAircraftTypeExplanation(requestMethod, flightDetails),
            shipmentExplanation,
            getFuelUsageExplanation(flightDetails),
            airShipmentFormulaExplanation,
        ].filter((s) => !!s.description)
    }

    let roadLoadExplanations: ExplanationStep | null = null
    let roadEnergyConsumptionExplanations: ExplanationStep | null = null
    if (isDetailedRoadEstimate) {
        roadLoadExplanations = getRoadLoadExplanations(
            requestMethod,
            methodologyDetails as RoadMethodologyDetails,
        )
        roadEnergyConsumptionExplanations = getRoadEnergyConsuptionExplanation(
            requestMethod,
            methodologyDetails as RoadMethodologyDetails,
        )
    }
    let electricEnergyConsumptionExplanation: ExplanationStep | null = null
    if (isElectricityDerivedMethod) {
        electricEnergyConsumptionExplanation =
            getElectricEnergyConsumptionExplanation(methodologyDetails)
    }

    return [
        inferredVesselExplanation.unwrapOr(null),
        roadLoadExplanations,
        roadEnergyConsumptionExplanations,
        electricEnergyConsumptionExplanation,
        distanceExplanation,
        emissionFactorExplanation,
        shipmentExplanation,
        formulaExplanation,
    ]
        .filter((s): s is Exclude<typeof s, null> => s !== null)
        .filter((s) => !!s.description)
}

export default useExplanationSteps
